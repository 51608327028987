import MainLayout from '@/components/main-layout';
import SEO from '@/components/seo';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql } from 'gatsby';
import React from 'react';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

type FaqProps = {
  data: any;
};

const Faq: React.FC<FaqProps> = ({ data }) => {
  const {
    allContentfulFaq: { nodes: faqs },
  } = data;

  return (
    <>
      <SEO
        title="Faq"
        description="Nájdite odpovede na najčastejšie kladené otázky."
        image="/faq.jpg"
      />
      <MainLayout>
        <div className="container m-auto text-white md:px-20 px-4 py-20">
          <Accordion allowZeroExpanded>
            {faqs.map((faq) => (
              <AccordionItem key={faq.id}>
                <AccordionItemHeading>
                  <AccordionItemButton>{faq.question}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="rich-text">
                    {documentToReactComponents(JSON.parse(faq.answer.raw))}
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </MainLayout>
    </>
  );
};

export const query = graphql`
  {
    allContentfulFaq {
      nodes {
        answer {
          raw
        }
        question
        id
      }
    }
  }
`;

export default Faq;
